import './assets/styles/App.css';
import Gallery from './components/Gallery';
import React from 'react';
import Splash from './components/Splash';
import { getGallery } from './utils';
import { useEffect } from 'react';
import { useState } from 'react';

function App() {
  const [pictures, setPictures] = useState([]);
  const [galleryConfig, setGalleryConfig] = useState({});
  const [splashReady, setSplashReady] = useState(false);
  const [layout, setLayout] = useState({ screenHeight: 0, screenWidth: 0 });

  useEffect(() => {
    loadGallery();

    let updateLayoutTimeOut = null;
    window.addEventListener('resize', () => {
      clearTimeout(updateLayoutTimeOut);
      updateLayoutTimeOut = setTimeout(updateLayout, 100);
    });
  
    updateLayout();
  }, [])

  function updateLayout() {
    setLayout({
      screenHeight: document.documentElement.clientHeight,
      screenWidth: document.documentElement.clientWidth,
    })
  }

  async function loadGallery() {
    const gallery = await getGallery();
    if (typeof gallery.config.biggerThumbnails === 'undefined') {
      gallery.config.biggerThumbnails = [];
    }

    document.title = gallery.config.title;
    setGalleryConfig(gallery.config);
    setPictures(
      gallery.pictures.map((p, index) => {
        return {
          id: index,
          ...p
        }
      })
    );
  }

  function handleContextMenu(e) {
    if (window.location.href.indexOf('localhost') < 0) {
      if (typeof galleryConfig.download !== 'undefined') {
        e.preventDefault();
        if (window.confirm('Le lien pour télécharger les photos en haute qualité se trouve en haut de la page. Souhaitez-vous ouvrir ce lien maintenant ?')) {
          window.open(galleryConfig.download, '_blank').focus();
        }
      }
    }
  }

  function setSplashReadyTimeOut() {
    setTimeout(() => {
      window.scrollTo(0, 0);
      setSplashReady(true);
    }, 1000);
  }

  return (
    <>
      <div className={'app-loading-ring' + (splashReady ? ' fadeout' : '')}/>
      <div
        className={'app' + (splashReady ? ' fadein' : '')}
        onContextMenu={handleContextMenu}
      >
        <Splash
          config={galleryConfig}
          onReady={setSplashReadyTimeOut}
          layout={layout}
        />
        <Gallery
          biggerThumbnailOdds={galleryConfig.biggerThumbnailOdds}
          biggerThumbnails={galleryConfig.biggerThumbnails}
          picturesProperties={pictures}
          layout={layout}
        />
        <a href='mailto:vandevelde.sylvain@gmail.com'>
          <div className='copyright'>
            Sylvain Vandevelde - {(new Date()).getFullYear()}
          </div>
        </a>
      </div>
    </>
  );
}

export default App;
