import React from 'react';
import Slideshow from '../components/Slideshow';
import Thumbnail from '../components/Thumbnail';
import { appConfig } from '../utils';
import { useEffect } from 'react';
import { useState } from 'react';

const MARGIN = appConfig.margin;
const MAX_WIDTH = appConfig.maxWidth;
const MIN_COLUMNS_COUNT = appConfig.minColumnsCount;

function Gallery(props) {
  const { picturesProperties, biggerThumbnailOdds, layout, biggerThumbnails } = props;
  const [ thumbnailSize, setTumbnailSize ] = useState(MAX_WIDTH);
  const [ columnsCount, setColumnsCount ] = useState(MIN_COLUMNS_COUNT);
  const [ picture, setPicture ] = useState(null);
  const [ preloadNextPicture, setPreloadNextPicture ] = useState(null);
  const [ preloadPreviousPicture, setPreloadPreviousPicture ] = useState(null);
  const [ slideshowOpenCount, setSlideshowOpenCount ] = useState(0);
  const [ pictures, setPictures ] = useState([]);

  useEffect(() => {
    const galleryWidth = layout.screenWidth - (2 * MARGIN);
    const columnsCount = Math.floor((galleryWidth + MARGIN) / (MAX_WIDTH + MARGIN)) + 1;
    const safeColumnsCount = columnsCount < MIN_COLUMNS_COUNT ? MIN_COLUMNS_COUNT : columnsCount;
    const thumbnailSize = (galleryWidth - (MARGIN * (safeColumnsCount - 1))) / safeColumnsCount;

    setTumbnailSize(thumbnailSize);
    setColumnsCount(safeColumnsCount);
  }, [layout.screenWidth])

  useEffect(() => {
    setPictures(
      picturesProperties.map(pp => {
        const biggerThumbnailOdd = Math.random() < 1 / biggerThumbnailOdds;
        const biggerThumbnailForced = biggerThumbnails.filter(bt => pp.largeUrl.indexOf(bt) > -1).length > 0;
        
        pp.layout = null;
        if (biggerThumbnailOdd || biggerThumbnailForced) {
          const ratio = pp.width / pp.height;
          pp.layout = 'square';
          if (ratio < 5 / 6) pp.layout = 'vertical';
          if (ratio > 5 / 4) pp.layout = 'horizontal';
        }

        return pp;
      })
    );
  }, [picturesProperties, biggerThumbnailOdds, biggerThumbnails])
  
  function next() {
    let newIndex = picture.id + 1;
    if (newIndex === pictures.length) newIndex = 0;
    setPicture(pictures[newIndex]);

    updatePreloads(newIndex);
  }
  
  function previous() {
    let newIndex = picture.id - 1;
    if (newIndex === -1) newIndex = pictures.length - 1;
    setPicture(pictures[newIndex]);
    
    updatePreloads(newIndex);
  }

  function updatePreloads(index) {
    let nextIndex = index + 1;
    if (nextIndex === pictures.length) nextIndex = 0;
    setPreloadNextPicture(pictures[nextIndex]);

    let previousIndex = index - 1;
    if (previousIndex === -1) previousIndex = pictures.length - 1;
    setPreloadPreviousPicture(pictures[previousIndex]);
  }

  function updatePicture(p) {
    setSlideshowOpenCount(slideshowOpenCount + 1);
    setPicture(p);
    updatePreloads(p.id);
  }

  return (
    <div
      className="gallery"
      style={{
        display: 'grid',
        gridAutoFlow: 'dense',
        gridAutoRows: thumbnailSize + 'px',
        gridGap: MARGIN + 'px',
        gridTemplateColumns: 'repeat(' + columnsCount + ', 1fr)',
        margin: MARGIN + 'px',
      }}
    >
      {
        preloadNextPicture === null ? null :
        <img
          alt="previous preload"
          src={preloadNextPicture.largeUrl}
          style={{ display: 'none' }}
        />
      }
      {
        preloadPreviousPicture === null ? null :
        <img
          alt="next preload"
          src={preloadPreviousPicture.largeUrl}
          style={{ display: 'none' }}
        />
      }
      {
        picture === null ? null :
        <Slideshow
          onExit={() => setPicture(null)}
          onNext={next}
          onPrevious={previous}
          picture={picture}
          showHint={slideshowOpenCount < 2}
        />
      }
      {
        pictures.map((p, index) => 
          <Thumbnail
            {...p}
            key={index} 
            onSelect={() => updatePicture(p)}
          />
        )
      }
    </div>
  );
}

export default Gallery;
