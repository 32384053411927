import '../assets/styles/thumbnail.css';
import React from 'react';
import VisibilitySensor from './VisibiltySensor';
import { useState } from 'react';

function Thumbnail(props) {
  const { smallUrl, mediumUrl, layout, onSelect } = props;
  const [ loaded, setLoaded ] = useState(false);
  const [ hasAppearedOnScreen, setHasAppearedOnScreen ] = useState(false);

  function getContainerStyle() {
    let columnsCount = 1;
    let rowsCount = 1;

    if (layout !== null) {
      columnsCount = layout === 'horizontal' ? 3 : 2;
      rowsCount = layout === 'vertical' ? 3 : 2;
    }

    return {
      width: "100%",
      height: "100%",
      gridColumn: 'span ' + columnsCount,
      gridRow: 'span ' + rowsCount,
    }
  }

  function renderPicture() {
    return (
      <div
        onClick={onSelect}
        className="picture"
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: '#e8e8e8',
          backgroundImage: `url(${layout === null ? smallUrl : mediumUrl})`
        }}
      />
    );
  }
    
  function renderLoadingBox() {
    if (!hasAppearedOnScreen) {
      return (
        <VisibilitySensor
          onVisibilityChange={setHasAppearedOnScreen}
          rootMargin={`${window.innerHeight}px 0px`}
          style={{ width: "100%", height: "100%", backgroundColor: '#e8e8e8' }}
        />
      );
    }

    return (
      <div
        style={{ 
          width: "100%",
          height: "100%",
          backgroundColor: '#e8e8e8',
          position: 'relative'
        }}
      >
        <img
          alt="loading"
          onLoad={() => setLoaded(true)}
          src={layout === null ? smallUrl : mediumUrl}
          style={{ display: 'none' }}
        />
      </div>
    );
  }

  function renderComponent() {
    if (loaded) {
      return renderPicture();
    } else {
      return renderLoadingBox();
    }
  }

  return (
    <div className="thumbnail" style={getContainerStyle()}>
      {renderComponent()}      
    </div>
  );
}

export default Thumbnail;