export const appConfig = {
  margin: isMobile() ? 2 : 5,
  maxWidth: 200,
  minColumnsCount: 4
};

export async function getGallery() {
  const response = await window.fetch('./index.php/gallery', {cache: 'no-store'});
  return response.json();
};

function isMobile() {
  return typeof window.orientation !== 'undefined';
};