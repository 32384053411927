import React, { useEffect, useRef } from 'react';

export default function VisibilitySensor({ style, rootMargin="0px", onVisibilityChange, children }) {
  const ref = useRef();

  useEffect(() => {
    const { current } = ref;
    const observer = new IntersectionObserver(([entry]) => { onVisibilityChange(entry.isIntersecting) }, { rootMargin });
    if (current) {
      observer.observe(current);
    }
    
    return () =>  observer.unobserve(current);
  }, [onVisibilityChange, rootMargin]);

  return (
    <div ref={ref} style={style}>
      {children}
    </div>
  );
}