import '../assets/styles/splash.css';
import React from 'react'
import { Button } from 'antd';
import { Icon } from 'antd';
import { appConfig } from '../utils';
import { useEffect } from 'react';
import { useState } from 'react';

const MARGIN = appConfig.margin;

function Splash(props) { 
  const { config, onReady, layout } = props;
  const [safeDimensions, setSafeDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const screenWidth = layout.screenWidth;
    const screenHeight = layout.screenHeight;

    setSafeDimensions({
      width: screenWidth,
      height: screenWidth * 2 / 3 < screenHeight * 0.75 ? screenWidth * 2 / 3 : screenHeight * 0.75,
    });
  }, [layout.screenWidth, layout.screenHeight])

  return (
    !config.splash ? null :
    <div
      className="splash"
      style={{
        width: safeDimensions.width - (MARGIN * 2) + 'px',
        height: safeDimensions.height - (MARGIN * 2) + 'px',
        margin: MARGIN + 'px',
      }}
    >
      <img
        src={config.splash}
        onLoad={onReady}
        style={{ display: 'none' }}
        alt="loading-splash"
      />
      <div
        className="background"
        style={{ backgroundImage: `url(${config.splash})` }}
      />
      <div className="content">
        <div style={{ flex: 1 }} />
        <div className="text-container">
          <div className="title">
            {config.title}
          </div>
          <div className="date">
            {config.date}
          </div>
        </div>
        <div className="button-container">
          <a href={config.download} target="_blank" rel="noopener noreferrer">
            <Button type="primary">
              Télécharger l'album
              <Icon type="cloud-download" />
            </Button>
          </a>
        </div>
        <div style={{ flex: 1 }} />
        <div style={{ flex: 1 }} />
      </div>
    </div>
  );
}

export default Splash;