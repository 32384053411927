import '../assets/styles/slideshow.css';
import React from 'react';
import { Icon } from 'antd';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';

function FullScreenPicture(props) {
  const { picture, onExit, onPrevious, onNext, showHint } = props;
  const container = useRef(null);
  const [pictureReady, setPictureReady] = useState(false);

  useEffect(() => {
    container.current.focus();
  }, [])

  useEffect(() => {
    setPictureReady(false);
  }, [picture])

  function handleKeys(event) {
    const key = event.key;
    if (key === 'ArrowRight') onNext();
    if (key === 'ArrowLeft') onPrevious();
    if (key === 'Escape') onExit();
  }

  return (
    <div
      ref={container}
      className="slideshow"
      onKeyDown={handleKeys}
      tabIndex={0}
      style={{ 
        position: 'fixed',
        zIndex: 999,
        background: 'rgba(0, 0, 0, 0.75)',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
      }}
    >
      <div className={"actions-container" + (showHint ? " show-hint" : "")}>
        <div className="action-container" onClick={onExit}>
          <div style={{ flexGrow: 1 }}/>
          <Icon
            type="close-circle"
            theme="filled"
            rotate="0"
            className="action-icon"
          />
          <div style={{ flexGrow: 1 }}/>
        </div>
        <div className="h-separator" />
        <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 5 }}>
          <div className="action-container" onClick={onPrevious}>
            <div style={{ flexGrow: 1 }}/>
            <Icon
              type="play-circle"
              theme="filled"
              rotate="180"
              className="action-icon"
            />
            <div style={{ flexGrow: 1 }}/>
          </div>
          <div className="v-separator" />
          <div className="action-container" onClick={onNext}>
            <div style={{ flexGrow: 1 }}/>
            <Icon
              type="play-circle"
              theme="filled"
              rotate="0"
              className="action-icon"
            />
            <div style={{ flexGrow: 1 }}/>
          </div>
        </div>
      </div>
      {
        pictureReady ?
        <div
          style={{
            backgroundImage: `url(${picture.largeUrl})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            height: '100%',
            width: '100%'
          }}
        />
        :
        <>
          <img
            alt="loading"
            onLoad={() => setPictureReady(true)}
            src={picture.largeUrl}
            style={{ display: 'none' }}
          />
          <div className="app-loading-ring" />
        </>
      }
    </div>
  );
}

export default FullScreenPicture;
